<template>
  <v-card
    :id='id'
    :height='getHeightCanvas'
    :width='getWidthCanvas'
  >
    <div :id='getCanvaId' class='canvas-style' />
  </v-card>
</template>

<script>
  import axios from 'axios';
  import validateCpf from '../../../../../util/cpf';

  export default {
    name: 'Canvas',
    defaultLayout: 0,
    props: {
      values: {
        type: Object,
        required: true,
      },
      template: {
        type: Object,
        required: true,
      },
      id: {
        type: Number | String, // eslint-disable-line
        required: true,
      },
      customTemplate: {
        type: Object,
        default: null,
      },
    },
    data: function () {
      return {
        jsonValues: [],
        widthCanvas: '500',
        heightCanvas: '600',
      };
    },
    watch: {
      template: function () {
        this.mountImage();
      },
    },
    mounted: async function () {
      await this.getAllTemplate();
      await this.mountImage();
    },
    computed: {
      getCanvaId: function () {
        return `canva_${this.id}`;
      },
      getWidthCanvas: function () {
        return this.widthCanvas;
      },
      getHeightCanvas: function () {
        return this.heightCanvas;
      },
    },

    methods: {
      getAllTemplate: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/template/1',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.jsonValues = JSON.parse(result.data.data.data);
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },

      mountImage: function () {
        const canvas = document.getElementById(this.getCanvaId);
        let jsonById = {};

        if (this.customTemplate === null) {
          jsonById = this.jsonValues.find((findValue) => findValue.name === this.template.name);
        } else {
          jsonById = this.customTemplate;
        }

        this.widthCanvas = `${jsonById.width}mm`;
        this.heightCanvas = `${jsonById.height}mm`;

        this.$emit('isFrontAndBack', jsonById.isFrontAndBack);
        this.$emit('paperLayout', jsonById.layout || null);
        this.$emit('pageLayout', jsonById.pageConfig || null);
        this.$emit('fieldToSave', jsonById.fieldToSave || null);
        this.$emit('saveMirror', jsonById.saveMirrorValue || false);
        this.$emit('sizeLayout', {
          width: `${jsonById.width}mm`,
          height: `${jsonById.height}mm`,
        });

        const divBackground = document.createElement('img');
        divBackground.id = 'background';
        if (this.values && !this.values.blank) {
          divBackground.className = 'background';
          divBackground.src = jsonById.background;
        }
        divBackground.style.width = `${jsonById.width}mm`;
        divBackground.style.height = `${jsonById.height}mm`;
        divBackground.style.position = 'absolute';
        divBackground.style.left = 0;
        divBackground.style.top = 0;

        canvas.appendChild(divBackground);

        if (this.values && !this.values.blank) {
          jsonById.imagens.forEach((image) => {
            const divImg = document.createElement('img');
            if (this.values[image.description]) {
              divImg.src = this.base64ToImage(this.values[image.description]);
            } else if (image.defaultValue) {
              divImg.src = this.base64ToImage(image.defaultValue);
            }

            if (divImg.src) {
              divImg.id = image.description;
              divImg.style.width = `${image.width}mm`;
              divImg.style.height = `${image.height}mm`;
              divImg.style.position = 'absolute';
              divImg.style.left = `${image.x}mm`;
              divImg.style.top = `${image.y}mm`;
              if (image.rotate) {
                divImg.style.transform = image.rotate;
              }
              if (image.borderRadius) {
                divImg.style.borderRadius = `${image.borderRadius}px`;
              }

              canvas.appendChild(divImg);
            }
          });

          jsonById.texts.forEach((text) => {
            let valueVariable = this.values[text.field];
            const divCustom = document.createElement('span');

            if (jsonById.dictionary && jsonById.dictionary.length > 0) {
              const findValue = jsonById.dictionary.find((dictionary) => dictionary.field === text.field && valueVariable === dictionary.identifier);
              if (findValue) valueVariable = findValue.description;
            }

            if (text.endLine !== '') {
              const texts = [];
              let finalValue = '';
              let countCaracter = 1;
              let isLineFull = false;

              for (let count = 0; count < valueVariable.length; count++) {
                isLineFull = false;
                finalValue += valueVariable[count];
                if (countCaracter === Number(text.endLine)) {
                  finalValue += '\r\n';
                  finalValue = finalValue.trimStart();

                  texts.push(finalValue);
                  countCaracter = 0;
                  finalValue = '';
                  isLineFull = true;
                }
                countCaracter++;
              }
              if (!isLineFull) {
                finalValue = finalValue.trimStart();
                texts.push(finalValue);
              }
              valueVariable = texts.join('');
            }

            if (valueVariable && text.character !== '') {
              valueVariable = String(valueVariable).substring(0, text.character) || 'loren ipson';
            }
            if (text.field.toLowerCase() === 'assinatura_impossibilitada') {
              valueVariable = valueVariable === 'true' ? 'Assinatura impossibilitada' : '';
            }
            if (text.field.toLowerCase() === 'validade') {
              valueVariable = valueVariable || (this.template.name.indexOf('PC-BA') >= 0 ? 'Indeterminado' : 'Indeterminada');

              // Forces "Indeterminada" even if the record is wrong
              if (this.template.name.indexOf('PM-SC') >= 0) {
                if (['APCP'].includes(this.values.infoPorte)) {
                  valueVariable = valueVariable; // eslint-disable-line no-self-assign
                } else {
                  valueVariable = 'Indeterminada';
                }
              }
            }
            if (text.field.toLowerCase() === 'nome_mae' || text.field.toLowerCase() === 'filiacaoMae') {
              valueVariable = '';
            }
            if (text.field.toLowerCase() === 'nome_pai' || text.field.toLowerCase() === 'filiacaoPai') {
              const fatherName = this.values.nome_pai || this.values.filiacaoPai;
              const motherName = this.values.nome_mae || this.values.filiacaoMae;
              valueVariable = `${fatherName || ''} <br> ${motherName || ''}`;
            }
            if (text.field.toLowerCase() === 'cpf') {
              valueVariable = valueVariable.replace(/\D/g, '');
              valueVariable = valueVariable.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, '$1.$2.$3-$4');
              if (!validateCpf(valueVariable)) {
                const name = this.values.name || this.values.nome || this.values.full_name || this.values.fullName || this.values.nomeCompleto || this.values.nome_completo;
                this.$emit('validationError', { title: 'CPF Inválido', text: `CPF inválido para o cadastro ${name}. Verifique o CPF, número de caracteres do layout e pontuações.` });
              }
            }
            if (valueVariable) {
              divCustom.innerHTML = text.forceUppercase ? valueVariable.toUpperCase() : valueVariable;
            }

            divCustom.style.width = `${text.width}mm`;
            // divCustom.setAttribute('style', 'white-space: pre;');
            divCustom.style.fontSize = text.size;
            divCustom.style.fontFamily = text.font;
            divCustom.style.height = text.height;
            divCustom.style.position = 'absolute';
            divCustom.style.left = `${text.x}mm`;
            divCustom.style.top = `${text.y}mm`;
            if (text.rotate) {
              divCustom.style.transform = text.rotate;
            }
            if (text.lineHeight) {
              divCustom.style.lineHeight = text.lineHeight;
            }
            if (text.fontWeight !== '') {
              divCustom.style.fontWeight = text.fontWeight;
            }
            if (text.whiteSpace !== '') {
              divCustom.style.whiteSpace = text.whiteSpace;
            }
            if (text.maxWidth !== '') {
              divCustom.style.maxWidth = `${text.maxWidth}`;
            }
            if (text.textAlign !== '') {
              divCustom.style.textAlign = text.textAlign;
            }
            if (text.color !== '') {
              divCustom.style.color = `${text.color}`;
            }
            if (text.letterSpacing !== '') {
              divCustom.style.letterSpacing = `${text.letterSpacing}`;
            }

            canvas.appendChild(divCustom);
          });

          jsonById.titles.forEach((title) => {
            if (this.mustShowLabel(title.field)) {
              let textContent = title.field;
              const divCustomTitle = document.createElement('span');

              if (title.endLine !== '') {
                let finalValue = '';
                let countCaracter = 1;

                for (let count = 0; count < textContent.length; count++) {
                  finalValue += textContent[count];
                  if (countCaracter === Number(title.endLine)) {
                    finalValue += '\r\n';
                    countCaracter = 0;
                  }
                  countCaracter++;
                }
                valueVariable = finalValue;
              }

              if (title.character !== '') {
                textContent = String(textContent).substring(0, title.character) || 'loren ipson';
              }

              if (textContent) {
                divCustomTitle.textContent = title.forceUppercase ? textContent.toUpperCase() : textContent;
              }

              divCustomTitle.style.width = `${title.width}mm`;
              divCustomTitle.setAttribute('style', 'white-space: pre;');
              divCustomTitle.style.fontSize = title.size;
              divCustomTitle.style.fontFamily = title.font;
              divCustomTitle.style.height = title.height;
              divCustomTitle.style.position = 'absolute';
              divCustomTitle.style.left = `${title.x}mm`;
              divCustomTitle.style.top = `${title.y}mm`;
              if (title.rotate) {
                divCustomTitle.style.transform = title.rotate;
              }
              if (title.lineHeight) {
                divCustomTitle.style.lineHeight = title.lineHeight;
              }
              if (title.fontWeight !== '') {
                divCustomTitle.style.fontWeight = title.fontWeight;
              }
              if (title.whiteSpace !== '') {
                divCustomTitle.style.whiteSpace = title.whiteSpace;
              }
              if (title.textAlign !== '') {
                divCustomTitle.style.textAlign = title.textAlign;
              }
              if (title.color) {
                divCustomTitle.style.color = `${title.color}`;
              }
              canvas.appendChild(divCustomTitle);
            }
          });
        }
      },
      base64ToImage: function (img) {
        if (img.substring(0, 4).includes('http')) return img;
        return `data:image/jpg;base64,${img}`;
      },
      mustShowLabel: function (title) {
        if (title !== '(APOSENTADO)' && title !== '(INATIVO)' && title !== '(RESERVA)' && title !== '(REFORMADO)') {
          return true;
        }
        if (['APSP', 'APCP'].includes(this.values.infoPorte)) {
          if (this.template.name.indexOf('BOMBEIRO') >= 0 || this.template.name.indexOf('PM') >= 0) {
            return (title === '(RESERVA)' && this.values.reservaReforma && this.values.reservaReforma.toUpperCase() === 'RESERVA')
              || (title === '(REFORMADO)' && this.values.reservaReforma && this.values.reservaReforma.toUpperCase() === 'REFORMADO');
          }
          return true;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .theme--light >>> .v-card {
    color: inherit;
  }

  .canvas-style {
    width: 100%;
    height: 100%;
  };

  .background {
    position: absolute;
  };
  @font-face {
    font-family: crystal;
    src: url(../../../../../assets/fonts/crystal.TTF);
  }
  @font-face {
    font-family: ocrbstd;
    src: url(../../../../../assets/fonts/OCR_B_Std_Regular.otf);
  }
</style>
